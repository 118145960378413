












































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class Tags extends Vue {
  private tags = []

  @Prop({ type: Array, required: false })
  private itemTags: Array<any>

  @Prop({ type: Array, required: false })
  private itemConnections: Array<any>

  @Prop({ type: Boolean, required: false, default: false })
  private compactView: boolean

  private created() {
    if (this.itemTags) {
      this.tags = this.itemTags
    } else {
      this.itemConnections.forEach((value) => {
        this.tags.push(value.tag)
      })
    }
  }

  private get isMobile(): boolean {
    switch (this.$vuetify.breakpoint.name) {
      case 'sm':
      case 'md':
      case 'lg':
      case 'xl':
        return false
      default:
        return true
    }
  }
}
