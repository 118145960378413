var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isMobile || _vm.compactView)?_vm._l((_vm.tags),function(item){return _c('v-tooltip',{key:item.tagId,attrs:{"disabled":item.name == '',"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1 mb-1",style:({
            'background-color': item.backgroundColor,
            color: item.textColor,
            cursor: item.name != '' ? 'pointer' : 'default',
          })},'v-chip',attrs,false),on),[(item.icon)?[_c('v-icon',{attrs:{"small":"","color":item.textColor}},[_vm._v(" "+_vm._s(item.icon)+" ")])]:[_vm._v(" "+_vm._s(item.name.charAt(0))+" ")]],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])}):_vm._l((_vm.tags),function(item){return _c('v-chip',{key:item.tagId,staticClass:"mr-1 mb-1",style:({ 'background-color': item.backgroundColor, color: item.textColor })},[(item.icon)?_c('v-icon',{attrs:{"small":"","color":item.textColor,"left":item.name !== ''}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }